import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { api_getContentPageMenuLinks } from '~/backend/api_calls';

export default function useContentPageMenuLinks(props) {
	const [links, setLinks] = useState([]);

	useEffect(() => {
		api_getContentPageMenuLinks({
			sh: props.show_header,
			sm: props.show_main_menu,
			sf: props.show_footer,
			sf1: props.show_footer_1,
			sf2: props.show_footer_2,
			ss: props.show_sitemap,
			sb: props.show_sidebar
		})
			.then(json => {
				setLinks(json.items);
			})
			.catch(error => {
				console.log(error);
			});
	}, []);

	return [links];
}

useContentPageMenuLinks.propTypes = {
	show_header: PropTypes.bool,
	show_main_menu: PropTypes.bool,
	show_footer: PropTypes.bool,
	show_footer_1: PropTypes.bool,
	show_footer_2: PropTypes.bool,
	show_sitemap: PropTypes.bool,
	show_sidebar: PropTypes.bool
};
