import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { api_getApplicationDiscount } from '~/backend/api_calls';
import UserSession from '~/backend/user_session';

export const useApplicationDiscount = mode => {
	const { data, isLoading, error } = useQuery({
		queryKey: ['getApplicationDiscount', mode],
		queryFn: () => api_getApplicationDiscount(mode),
		keepPreviousData: true,
		refetchOnMount: false,
		refetchOnWindowFocus: true,
		enabled: UserSession.isAuthenticated(),
		staleTime: 1000 * 60 // 1 minute
	});
	return { discount: data?.discount, isLoading, error };
};

export const useApplicationDiscounts = () => {
	const { discount: own, isLoading: isLoadingMyAppDiscount } = useApplicationDiscount('me');
	const { discount: friends, isLoading: isLoadingAppDiscount } = useApplicationDiscount('friends');
	return useMemo(
		() => ({ own: own, friends: friends, isLoading: isLoadingAppDiscount || isLoadingMyAppDiscount }),
		[friends, isLoadingAppDiscount, isLoadingMyAppDiscount, own]
	);
};
