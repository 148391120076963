import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { api_getStatusByUrl, api_searchProductsWithFilters } from '~/backend/api_calls';
import CategoryMemory from '~/backend/category_memory.js';
import PersonalisedProducts from '~/backend/personalised_products.js';
import { selectItemGTAG4Event, viewItemListGTAG4Event } from '~/common/gtag4.js';
import { attachIndexesToProducts } from '~/common/helper.js';
import { dropDownSelectStylesGridSorting, dropDownSelectStylesPageSize, focusSelect, unFocusSelect } from '~/common/selects.js';
import FooterTopBoxes from '~/components/common/FooterTopBoxes.js';
import PageHeaderMeta from '~/components/common/PageHeaderMeta.js';
import Pager from '~/components/common/Pager.js';
import ProductCard from '~/components/common/ProductCard/ProductCard.js';
import { CatalogFilterLoader, ProductListLoader } from '~/components/common/SkeletonLoaders.js';
import DiscountCode from '~/components/home/DiscountCode.js';
import ScrollRestoration from '~/core/filters/hooks/ScrollRestoration.js';
import useApplyFilters from '~/core/filters/hooks/useApplyFilters.js';
import useFilters from '~/core/filters/hooks/useFilters.js';
import CatalogFilters from '~/pages/Category/CatalogFilters.js';

const excludedFiltersPerCategoryCode = [
	{
		categoryCode: 'MAN',
		filterCodes: [35]
	},
	{
		categoryCode: 'WOMAN',
		filterCodes: [35]
	},
	{
		categoryCode: 'INFANT',
		filterCodes: [35]
	}
];

const StatusList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	let { eurl } = useParams();
	const [previousEurl, setPreviousEurl] = useState(null);

	const [firstRender, setFirstRender] = useState(true);
	const [category, setCategory] = useState({});
	const [productsLoading, setProductsLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const [productsCount, setProductsCount] = useState(0);
	const scrollData = JSON.parse(sessionStorage.getItem('scrollData'));

	const { setSorting, setViewPerPage } = useApplyFilters();
	const {
		filters,
		filtersUrl,
		page,
		filtersLoading,
		viewPerPageOptions,
		viewPerPageOption,
		gridSortOptions,
		sortingOption,
		filtersModalVisible,
		setFilters,
		setFiltersLoading,
		handleSetViewAllProductsOption,
		setFiltersModalVisible
	} = useFilters();

	const categoryCached = CategoryMemory.url === eurl && !category.id;

	// Category Memory
	useEffect(() => {
		if (categoryCached) {
			setCategory(CategoryMemory.category);
			setProducts(CategoryMemory.products);
			setFilters(CategoryMemory.filters);
			setProductsCount(CategoryMemory.totalCount);
		}

		return () => {
			CategoryMemory.url = eurl;
			CategoryMemory.category = category;
			CategoryMemory.products = products;
			CategoryMemory.filters = filters;
			CategoryMemory.page = page;
			CategoryMemory.totalCount = productsCount;
		};
	}, [products, filters, sortingOption, page, viewPerPageOption]);

	useEffect(() => {
		if (products.length > 0) {
			if (firstRender && scrollData?.eurl === eurl && scrollData?.page == page) {
				setFirstRender(false);
				window.scroll(0, scrollData.scrollPosition - 50);
			}
		}
	}, [products]);

	useEffect(() => {
		if (categoryCached) {
			return;
		}
		fetchData();
	}, [eurl, filtersUrl, sortingOption, page, viewPerPageOption]);

	const fetchData = async () => {
		try {
			let categoryJson = category;

			if (previousEurl !== eurl) {
				setFiltersLoading(true);
				categoryJson = await api_getStatusByUrl(eurl);
				setCategory(categoryJson);
			}

			internalLoadProducts(categoryJson.id, filtersUrl, sortingOption, page, viewPerPageOption);

			setPreviousEurl(eurl);
		} catch (error) {
			console.log(error);
			navigate('/');
		}
	};

	const internalLoadProducts = (categoryId, filter, sort, page, size) => {
		if (!categoryId) return;

		setProductsLoading(true);
		api_searchProductsWithFilters('', categoryId, filter, sort, page, size)
			.then(json => {
				setProductsCount(json.total_count);

				// const productsJson = json.items.filter(x => x.img1);

				setProducts(json.items);
				handleSetViewAllProductsOption(json.total_count, 120, 300, 'ΟΛΑ');
				PersonalisedProducts.add(json.items);

				const filtersJson = json.flst.filter(filter => {
					const specialCategory = excludedFiltersPerCategoryCode.find(x => x.categoryCode === category.cd);

					if (specialCategory?.filterCodes?.includes(filter.id)) return false;

					return true;
				});

				setFilters(filtersJson);

				// Setting the index needed for the google event
				let startIndex = 0;
				if (page > 1) {
					startIndex = (page - 1) * viewPerPageOption;
				}
				viewItemListGTAG4Event(attachIndexesToProducts(json.items, startIndex), `Category: ${category.nm}`);
			})
			.catch(error => console.log(error))
			.finally(() => {
				setProductsLoading(false);
				setFiltersLoading(false);
			});
	};

	return (
		<>
			<PageHeaderMeta
				page_title={category.nm}
				meta_keywords={category.mk}
				meta_description={category.md}
				image={category.img1}
				type={'product.group'}
			/>

			<ScrollRestoration categoryId={category?.id} />

			<div className="category-page page">
				{/* <Breadcrumbs breadcrumbs={category.breadcrumbs} currentName={category.nm} /> */}
				<div className="category-list-page">
					<div className="content-wrapper">
						<div className="category-list-container">
							{filtersLoading ? (
								<CatalogFilterLoader />
							) : (
								<CatalogFilters
									productsLoading={productsLoading}
									filters={filters}
									filtersModalVisible={filtersModalVisible}
									setFiltersModalVisible={setFiltersModalVisible}
									excludedFilterTypes={[1]}
								/>
							)}

							<div className="products-section">
								<div className="category-header">
									{!productsLoading ? <h1 className="category-title">{category.nm}</h1> : <h1></h1>}

									<div className="view-options">
										{/* Filters button display on mobile devices */}
										<button className="filters" onClick={() => setFiltersModalVisible(true)}>
											{t('filters_title')}
										</button>
										{/* Filters button display on mobile devices */}

										{/* View Per Page and Sorting Options */}
										<Select
											id="viewPerPage"
											options={viewPerPageOptions}
											onMenuClose={() => {
												unFocusSelect('viewPerPage');
											}}
											onMenuOpen={() => {
												focusSelect('viewPerPage');
											}}
											onChange={e => setViewPerPage(e.value)}
											value={viewPerPageOptions.find(x => x.value == viewPerPageOption)}
											defaultValue={viewPerPageOptions[0]}
											styles={dropDownSelectStylesPageSize}
										/>
										<Select
											id="sorting"
											placeholder={t('sort')}
											options={gridSortOptions}
											onMenuClose={() => {
												unFocusSelect('sorting');
											}}
											onMenuOpen={() => {
												focusSelect('sorting');
											}}
											onChange={e => setSorting(e.value)}
											value={gridSortOptions.find(x => x.value === sortingOption) ?? 'select'}
											styles={dropDownSelectStylesGridSorting}
										/>
										{/* View Per Page and Sorting Options */}
									</div>
								</div>

								{!productsLoading && <h4 className="category-products-count">Σύνολο Προϊόντων {productsCount}</h4>}

								{!productsLoading ? (
									<div className="category-desc" dangerouslySetInnerHTML={{ __html: category.itxt1 }}></div>
								) : (
									<div></div>
								)}

								<div className="product-list">
									{productsLoading ? (
										<ProductListLoader />
									) : (
										products.map((product, index) => (
											<ProductCard key={index} product={product} onClick={() => selectItemGTAG4Event(product, category.nm)} />
										))
									)}
								</div>
								{productsCount > 0 && <Pager totalCount={productsCount} itemsPerPage={viewPerPageOption} activePage={page} />}
							</div>
						</div>
					</div>
				</div>

				{!filtersModalVisible && <button className="filters-button" onClick={() => setFiltersModalVisible(true)}></button>}
			</div>

			{/* <FixedButtons showTelephone={false} /> */}
			<DiscountCode />
			<FooterTopBoxes />
		</>
	);
};

export default StatusList;
