import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '~/components/common/Modal';
import { setShowNotification } from '~/store/actions';

const ApplicationModeNotification = ({ children }) => {
	const showNotification = useSelector(state => state.applicationReducer.showNotification);
	const dispatch = useDispatch();

	const setOpen = show => {
		dispatch(setShowNotification(show));
	};

	return (
		<div className="application-poupup-modal">
			<Modal open={showNotification} setOpen={setOpen} className="application-popup">
				<div className="application-popup-container">{children}</div>
			</Modal>
		</div>
	);
};

ApplicationModeNotification.propTypes = {
	open: PropTypes.bool
};

export default ApplicationModeNotification;
