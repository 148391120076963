// CheckoutSessionStorage object defined as a closure wrapper
var CheckoutSessionStorage = (function () {
	var getOrder = function () {
		try {
			var order = JSON.parse(sessionStorage.getItem('checkout_order'));

			if (order == null) {
				order = {};
			}

			setOrder(order);
			return order;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var setOrder = function (order) {
		try {
			if (order == null) {
				order = {};
			}

			sessionStorage.setItem('checkout_order', JSON.stringify(order));
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var getCoupons = function () {
		try {
			var coupons = JSON.parse(sessionStorage.getItem('checkout_coupons'));

			if (coupons == null) {
				coupons = [];
			} else if (!Array.isArray(coupons) || !coupons.every(element => typeof element === 'string')) {
				coupons = [];
			}

			setCoupons(coupons);
			return coupons;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var setCoupons = function (coupons) {
		try {
			if (coupons == null) {
				coupons = [];
			} else if (!Array.isArray(coupons) || !coupons.every(element => typeof element === 'string')) {
				coupons = [];
			}

			sessionStorage.setItem('checkout_coupons', JSON.stringify(coupons));
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var getGiftcards = function () {
		try {
			var giftcards = JSON.parse(sessionStorage.getItem('checkout_giftcards'));

			if (giftcards == null) {
				giftcards = [];
			} else if (!Array.isArray(giftcards) || !giftcards.every(element => typeof element === 'string')) {
				giftcards = [];
			}

			setGiftcards(giftcards);
			return giftcards;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var setGiftcards = function (giftcards) {
		try {
			if (giftcards == null) {
				giftcards = [];
			} else if (!Array.isArray(giftcards) || !giftcards.every(element => typeof element === 'string')) {
				giftcards = [];
			}

			sessionStorage.setItem('checkout_giftcards', JSON.stringify(giftcards));
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var getLoyaltyPoints = function () {
		try {
			var points = JSON.parse(sessionStorage.getItem('checkout_loyaltypoints'));

			if (points == null) {
				points = 0;
			} else if (typeof points !== 'number' || !Number.isInteger(points)) {
				points = 0;
			}

			setLoyaltyPoints(points);
			return points;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var setLoyaltyPoints = function (point) {
		try {
			if (point == null) {
				point = 0;
			}
			sessionStorage.setItem('checkout_loyaltypoints', JSON.stringify(point));
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var clear = function () {
		try {
			sessionStorage.removeItem('checkout_order');
			sessionStorage.removeItem('checkout_coupons');
			sessionStorage.removeItem('checkout_giftcards');
			sessionStorage.removeItem('checkout_loyaltypoints');
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	return {
		getOrder,
		setOrder,
		getCoupons,
		setCoupons,
		getGiftcards,
		setGiftcards,
		getLoyaltyPoints,
		setLoyaltyPoints,
		clear
	};
})();

export default CheckoutSessionStorage;
