import { call, put, takeLatest } from 'redux-saga/effects';

//import { useNavigate } from "react-router-dom";

// Checkout redux actions
import { checkoutErrorAction, orderCompleteSuccessAction } from './actions';

// API calls
import { api_saveOrder } from '~/backend/api_calls';

function* processOrder(action) {
	try {
		const response = yield call(api_saveOrder, action.order);

		yield put(orderCompleteSuccessAction(response));
	} catch (error) {
		yield put(checkoutErrorAction(error));
	}
}

function* checkoutSaga() {
	yield takeLatest('order-completed', processOrder);
}

export default checkoutSaga;
