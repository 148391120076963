import { Link } from 'react-router-dom';
import espaBanner from '~/images/espa2023.jpg';

const EspaBanner = () => {
	return (
		<div className="espa-banner">
			<Link to={'pages/epixeirhsiako-programma-antagonistikothta-epixeirhmatikothta--kainotomia-epanek'}>
				<img src={espaBanner} alt="Espa Banner" />
			</Link>
		</div>
	);
};

export default EspaBanner;
