import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { api_getMainMenu } from '~/backend/api_calls.js';
import CartWidget from '~/components/common/CartWidget.js';
import followIcon from '~/images/follow-order.svg';
import menu from '~/images/menu.svg';
import returnIcon from '~/images/return-order.svg';
import '~/css/layout.scss';

import ApplicationMode from '../application/ApplicationMode.js';
import InfoMessage from '../header/InfoMessage.js';
import MenuWrapper from './Menu/MenuWrapper.js';
import NavButtons from './NavButtons.js';

const Header = () => {
	const location = useLocation();
	const [initialLocation] = useState(location);
	const openCartWidget = useSelector(state => state.cartReducer.showCartWidget);
	const [menuItems, setMenuItems] = useState([]);
	const [activeMenuItem, setActiveMenuItem] = useState({});
	const [activeSubMenuItem, setActiveSubMenuItem] = useState({});
	const underHeaderBannerRef = useRef(null);
	const [menuOpen, setMenuOpen] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		api_getMainMenu()
			.then(json => {
				setMenuItems(json.items);
			})
			.catch(error => {
				console.error(error);
			});
	}, []);

	useEffect(() => {
		if (initialLocation !== location) {
			setMenuOpen(false);
			setActiveMenuItem({});
			setActiveSubMenuItem({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<>
			{/* <InfoMessage className="topbar-message" code="top-message" hasCloseButton={false} /> */}
			<div className="top-container">
				<div className="order-links">
					<img src={returnIcon} alt="Return Icon" className="return-icon" />
					<Link to="/return-order">ΔΙΑΔΙΚΑΣΙΑ ΑΛΛΑΓΗΣ</Link>
				</div>
				<div className="order-links">
					<img src={followIcon} alt="Follow Icon" className="follow-icon" />
					<Link to={'/followorder'}>ΕΞΕΛΙΞΗ ΠΑΡΑΓΓΕΛΙΑΣ</Link>
				</div>
			</div>
			<div className="seo-menu">
				{menuItems.map((item, index) => (
					<Link key={index} to={item.nu}>
						{item.tl}
					</Link>
				))}
			</div>

			<header id="layout-header">
				<div className="header-inner">
					<ApplicationMode />
					<div className="content-wrapper">
						<div className="top">
							<div
								className="burger-btn"
								onClick={() => {
									setMenuOpen(true);
									setActiveMenuItem(false);
									setActiveSubMenuItem({});
								}}>
								<img src={menu} alt="Menu Icon" />
							</div>
							<div className="logo">
								<Link to="/">
									<img src={'/images/sportistas-logo-md.png'} alt="Sportistas" />
								</Link>
							</div>
							<NavButtons />
						</div>
						<MenuWrapper
							menuItems={menuItems}
							activeMenuItem={activeMenuItem}
							setActiveMenuItem={setActiveMenuItem}
							activeSubMenuItem={activeSubMenuItem}
							setActiveSubMenuItem={setActiveSubMenuItem}
							underHeaderBannerRef={underHeaderBannerRef}
							menuOpen={menuOpen}
							setMenuOpen={setMenuOpen}
						/>
					</div>
					<InfoMessage className="underheader-message-box" code="underheader-message" hasCloseButton={true} />
				</div>
			</header>

			{openCartWidget && <CartWidget />}
		</>
	);
};

export default Header;
