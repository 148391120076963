export const setApplicationMode = mode => {
	return {
		type: 'set-application-mode',
		mode
	};
};

export const setHasAcceptedApplicationTerms = hasAcceptedTerms => {
	return {
		type: 'set-has-accepted-application-terms',
		hasAcceptedTerms
	};
};

export const setIsApplicationUser = isApplicationUser => {
	return {
		type: 'set-is-application-user',
		isApplicationUser
	};
};

export const setApplicationDimensions = dimensions => {
	return {
		type: 'set-application-dimensions',
		dimensions
	};
};

export const setApplicationGender = gender => {
	return {
		type: 'set-application-gender',
		gender
	};
};

export const setShowNotification = showNotification => {
	return {
		type: 'set-show-notification',
		showNotification
	};
};
