export const openMobileMenuAction = () => {
	return {
		type: 'openMobileMenu'
	};
};

export const closeMobileMenuAction = () => {
	return {
		type: 'closeMobileMenu'
	};
};

export const openDesktopMenuAction = () => {
	return {
		type: 'openDesktopMenu'
	};
};

export const closeDesktopMenuAction = () => {
	return {
		type: 'closeDesktopMenu'
	};
};

export const setActiveCategoryId = categoryId => {
	return {
		type: 'setActiveCategoryId',
		categoryId
	};
};

export const addToWishlistAction = ({ productId }) => {
	return {
		type: 'add-to-wishlist',
		productId
	};
};

export const closeAddToWishlistAction = () => {
	return {
		type: 'close-add-to-wishlist'
	};
};

export const updateWishlistLastAction = handledProduct => {
	return {
		type: 'update-handled-product',
		handledProduct
	};
};

export const openCouponPopupAction = () => {
	return {
		type: 'open-coupon-popup'
	};
};

export const closeCouponPopupAction = () => {
	return {
		type: 'close-coupon-popup'
	};
};
