export const changeLanguage = (pathname, i18n) => {
	const oldLang = localStorage.getItem('I18N_LANGUAGE');
	const lang = localStorage.getItem('I18N_LANGUAGE') == 'el' ? 'en' : 'el';

	i18n.changeLanguage(lang);
	localStorage.setItem('I18N_LANGUAGE', lang);

	// Update url
	var url = '';

	if (pathname == null || pathname == '') {
		pathname = '/';
	}
	if (pathname.length > 3 && !pathname.startsWith('/el/') && !pathname.startsWith('/en/')) {
		url = lang + pathname;
	} else {
		if (pathname.startsWith('/' + oldLang)) {
			url = pathname.replace('/' + oldLang, lang);
		} else {
			url = lang + pathname;
		}
	}

	location.href = process.env.REACT_APP_CLIENT_URL + '/' + url;
};

export const convertUTCDateToLocalDate = date => {
	let newDate = new Date(date);
	newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
	return newDate;
};

export const dateFormatShort = {
	dateStyle: 'short',
	timeStyle: 'short',
	timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};
export const dateFormatLong = {
	dateStyle: 'medium',
	timeStyle: 'medium',
	timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

export function formatDateString(d) {
	if (d == null) return '';

	// Add UTC Offset, else it parses is as local datetime
	d = d.slice(0, 19) + '.000Z';

	return new Intl.DateTimeFormat('el-GR', dateFormatLong).format(new Date(d));
}

export const getGenderLabel = genderId => {
	let gender = '';

	if (genderId == 1) {
		gender = 'male';
	} else if (genderId == 2) {
		gender = 'female';
	} else if (genderId == 3) {
		gender = 'other';
	} else if (genderId == 4) {
		gender = 'noDisclose';
	}

	return gender;
};

export const isEmpty = obj => {
	return Object.keys(obj).length === 0;
};

export const sortLabels = arr => {
	return arr.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
};

export const emptyProfile = () => {
	return {
		eml: '',
		mob: '',
		dob: '',
		gd: '',
		fn: '',
		ln: '',
		id: ''
	};
};

export function formatAmountValue(value) {
	if (value != null) {
		return Number.parseFloat(value).toFixed(2).toString() + '€';
	}

	return '';
}

export function formatDiscountPercentage(discount) {
	let roundedDiscount = Math.round(discount);

	// Round the discount to the nearest 5% increment
	const remainder = roundedDiscount % 5;
	if (remainder >= 2.5) {
		roundedDiscount += 5 - remainder;
	} else {
		roundedDiscount -= remainder;
	}

	return roundedDiscount + '%';
}

export function calculateProductPrice(product, dimensionId) {
	if (dimensionId) {
		const dimension = product.dimensions.find(x => x.id == dimensionId);

		// console.log(dimension);
		if (dimension && dimension.prc) {
			if (dimension.prcbd > 0) {
				return formatAmountValue(dimension.prc);
			}

			if (dimension.dsc > 0 && dimension.dsc < 100) {
				return formatAmountValue(dimension.prc - (dimension.prc * dimension.dsc) / 100);
			}

			return formatAmountValue(dimension.prc);
		}
	}
	if (product.prcbd > 0) {
		return formatAmountValue(product.prc);
	}
	if (product.dsc > 0 && product.dsc < 100) {
		return formatAmountValue(product.prc - (product.prc * product.dsc) / 100);
	}

	return formatAmountValue(product.prc);
}

export function calculateProductPriceValue(product, dimensionId) {
	if (dimensionId) {
		const dimension = product.dimensions.find(x => x.id == dimensionId);

		if (dimension && dimension.prc) {
			if (dimension.prcbd > 0) {
				return dimension.prc;
			}

			if (dimension.dsc > 0 && dimension.dsc < 100) {
				return dimension.prc - (dimension.prc * dimension.dsc) / 100;
			}

			return dimension.prc;
		}
	}

	if (product.prcbd > 0) {
		return product.prc;
	}
	if (product.dsc > 0 && product.dsc < 100) {
		return product.prc - (product.prc * product.dsc) / 100;
	}

	return product.prc;
}

export function calculateProductPriceBD(product, dimensionId) {
	if (dimensionId) {
		const dimension = product.dimensions.find(x => x.id == dimensionId);

		if (dimension && dimension.prc) {
			if (dimension.prcbd > 0) {
				return formatAmountValue(dimension.prcbd);
			}

			if (dimension.dsc > 0 && dimension.dsc < 100) {
				return formatAmountValue(dimension.prc);
			}

			return null;
		}
	}

	if (product.prcbd > 0) {
		return formatAmountValue(product.prcbd);
	}
	if (product.dsc > 0 && product.dsc < 100) {
		return formatAmountValue(product.prc);
	}

	return null;
}

export function calculateProductPriceBDValue(product, dimensionId) {
	if (dimensionId) {
		const dimension = product.dimensions.find(x => x.id == dimensionId);

		if (dimension && dimension.prc) {
			if (dimension.prcbd > 0) {
				return dimension.prcbd;
			}

			if (dimension.dsc > 0 && dimension.dsc < 100) {
				return dimension.prc;
			}
		}
	}

	if (product.prcbd > 0) {
		return product.prcbd;
	}
	if (product.dsc > 0 && product.dsc < 100) {
		return product.prc;
	}

	return null;
}

export const attachIndexesToProducts = (products, startIndexFrom = 0) => {
	const test = products.map((product, index) => {
		return { ...product, index: index + startIndexFrom };
	});

	return test;
};

// export const calculateProductPriceWithActivatedCoupons = (product) => {

//     const productId = product.id;
//     const categoriesIds = product.catlst;

//     const activatedCoupons = JSON.parse(localStorage.getItem("activated_coupons")) ?? [];

//     const couponsAttachedToProduct =  activatedCoupons.filter(coupon => {
//         const { cats, prods } = coupon;
//         const hasCommonCat = cats.some(catId => categoriesIds.includes(catId));
//         const hasProduct = prods.includes(productId);
//         return (hasCommonCat || hasProduct) && !coupon.applyOnOrder;
//     });

//     if (couponsAttachedToProduct.length == 0) {
//         return calculateProductPrice(product);
//     };

//     const biggestDiscountCoupon = couponsAttachedToProduct.reduce((maxCoupon, coupon) => {
//         return coupon.discount > maxCoupon.discount ? coupon : maxCoupon;
//     });

//     let productPriceBeforeDiscounts = 0;
//     let discountAttachedOnProduct = 0;

//     if (dimension.prcbd > 0) {
//         productPriceBeforeDiscounts = product.prcbd;
//         discountAttachedOnProduct = product.prcbd - product.prc;
//     } else if (product.dsc > 0 && product.dsc < 100) {
//         productPriceBeforeDiscounts = product.prc;
//         discountAttachedOnProduct = product.prc * product.dsc / 100;
//     } else {
//         productPriceBeforeDiscounts = product.prc
//     }

//     let finalProductPrice;

//     if (discountAttachedOnProduct - biggestDiscountCoupon.discount > 0) {
//         finalProductPrice = productPriceBeforeDiscounts - discountAttachedOnProduct;
//     } else {
//         finalProductPrice = productPriceBeforeDiscounts - biggestDiscountCoupon.discount;
//     }

//     return formatAmountValue(finalProductPrice);

// }

export function getCartProductImage(cartProduct) {
	if (cartProduct.dimension) {
		const selectedDimension = cartProduct.product.dimensions.filter(dimension => dimension.id == cartProduct.dimension);

		if (!selectedDimension) {
			// should not reach here
			return cartProduct.product.img1;
		}
		console.log(selectedDimension);

		// return any selected dimensions image
		if (selectedDimension.img1) return selectedDimension.img1;
		if (selectedDimension.img2) return selectedDimension.img2;
		if (selectedDimension.img3) return selectedDimension.img3;
		if (selectedDimension.img4) return selectedDimension.img4;
	}

	return cartProduct.product.img1;
}
//export const setPassVisibillityClickListener = () => {
//    $('#togglePassVisibillity').click(function () {
//        let passInput = $(this).parent().find('input');
//        let showIcon = $(this).find('#showPassIcon');
//        let hideIcon = $(this).find('#hidePassIcon');

//        if ($(passInput).prop("type") == 'text') {
//            $(passInput).prop("type", "password");
//            $(showIcon).show();
//            $(hideIcon).hide();
//        } else {
//            $(passInput).prop("type", "text");
//            $(hideIcon).show();
//            $(showIcon).hide();
//        }
//    });
//};

export function normalize(text) {
	return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function parseJwt(token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}

export const initFacebookSdk = () => {
	return new Promise(resolve => {
		// wait for facebook sdk to initialize before starting the react app
		window.fbAsyncInit = function () {
			window.FB.init({
				appId: process.env.REACT_APP_FACEBOOK_APP_ID,
				cookie: false,
				xfbml: true,
				version: 'v16.0'
			});

			resolve();
		};

		// load facebook sdk script
		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = 'https://connect.facebook.net/en_US/sdk.js';
			fjs.parentNode.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	});
};

export const getRegisterStoreName = id => {
	switch (`${id}`.toLowerCase()) {
		case '1':
		case 'faliro':
			return 'Φάληρο';
		case '2':
		case 'glyfada':
			return 'Γλυφάδα';
		case '3':
		case 'kifisia':
			return 'Κηφισιά';
		case '4':
		case 'mcarthur':
			return 'McArthur';
		case '5':
		case 'sportistas':
			return 'Sportistas';
		default:
			return 'store';
	}
};

export const getRegisterStoreERPCode = id => {
	switch (`${id}`.toLowerCase()) {
		case '1':
		case 'faliro':
			return 'ΦΑΛΗΡΟ';
		case '2':
		case 'glyfada':
			return 'ΓΛΥΦΑΔΑ';
		case '3':
		case 'kifisia':
			return 'ΚΗΦΙΣΙΑ';
		case '4':
		case 'mcarthur':
			return 'MAC ARTHUR';
		case '5':
		case 'sportistas':
			return 'CENTRAL';
		default:
			return null;
	}
};

export const chunkArray = (array, chunkSize) => {
	let chunks = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		chunks.push(array.slice(i, i + chunkSize));
	}
	return chunks;
};
