import { toast } from 'react-toastify';

export const notifyError = (message, closeAction = null, autoClose = 3000) => {
	// Remove all toasts !
	toast.dismiss();

	toast.error(message, {
		position: toast.POSITION.TOP_CENTER,
		toastId: 'nfyRegisterApiError' + new Date().getTime(),
		onClose: closeAction,
		className: 'black-background',
		autoClose: autoClose
	});
};

export const notifyInfo = (message, closeAction = null, autoClose = 3000) => {
	// Remove all toasts !
	toast.dismiss();

	toast.info(message, {
		position: toast.POSITION.TOP_CENTER,
		toastId: 'nfyRegisterMsg' + new Date().getTime(),
		onClose: closeAction,
		className: 'black-background',
		autoClose: autoClose
	});
};

export const clearNotifications = () => {
	toast.dismiss();
};
