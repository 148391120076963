import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '~/components/common/Button';
import { setShowNotification } from '~/store/actions';
import { percentageFormat } from '../../../../utils/percentageFormat';
import { useApplicationDiscount } from '../useApplicationDiscount';

const ShopForFriends = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { discount, isLoading, error } = useApplicationDiscount('friends');
	if (isLoading) return null;
	if (error || discount == null) return null;
	return (
		<>
			<div className="header">
				<div className="title">
					{t('application_from_now_on')} <br />
					<span>{t('application_your_friends')}</span>
					<span>
						{' '}
						{}{' '}
						{discount > 0 ? (
							<>
								{t('application_with_discount')} {percentageFormat.format(discount / 100)}
							</>
						) : (
							<>ΜΕ {t('application_discounts')}</>
						)}{' '}
					</span>
				</div>
			</div>
			<div className="body"></div>
			<div className="footer">
				<Button color="secondary" actionOnClick={() => dispatch(setShowNotification(false))}>
					{t('close')}
				</Button>
			</div>
		</>
	);
};

ShopForFriends.propTypes = {
	setOpen: PropTypes.func
};

export default ShopForFriends;
