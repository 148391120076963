import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { convertImageUrl_S_to_M } from '~/backend/helper';
import wishlistIconActive from '~/images/wishlist-icon-active.svg';
import wishlistIcon from '~/images/wishlist-icon.svg';

// Demo import

const ProductCardImage = ({ product }) => {
	return (
		<div className="product-img">
			<Link to={product.eurl}>
				<img className="img" src={convertImageUrl_S_to_M(product.image)} alt={product.nm} loading="lazy" width="218px" height="218px" />
			</Link>
			<div className="wishlist-icon">
				<button type="button">
					<img
						className="active"
						src={!product.wishlistActive ? wishlistIcon : wishlistIconActive}
						onClick={() => product.handleWishlistToggle()}
						alt="wishlist icon "
					/>
				</button>
			</div>
		</div>
	);
};

ProductCardImage.propTypes = {
	product: PropTypes.object
};
export default ProductCardImage;
