import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import useApplyFilters from '~/core/filters/hooks/useApplyFilters';
import pagerArrowSVG from '~/images/pager-arrow.svg';

const Pager = ({ totalCount, itemsPerPage, activePage }) => {
	const { setPage } = useApplyFilters();
	const [pages, setPages] = useState([]);
	const activePageNumber = Number(activePage);
	const totalPageCount = Math.max(totalCount / itemsPerPage);

	const composePages = () => {
		let pagesArray = [];
		for (let i = 0; i < totalCount / itemsPerPage; i++) {
			let page = i + 1;
			pagesArray.push(page);
		}

		const pageCount = pagesArray.length;

		if (pageCount <= 5) {
			setPages(pagesArray);
			return;
		}

		if (activePageNumber === 1) {
			pagesArray = [1, 2, 3, 0, pageCount];
		} else if (activePageNumber === pageCount) {
			pagesArray = [1, 0, pageCount - 2, pageCount - 1, pageCount];
		} else if (pageCount - activePageNumber < activePageNumber - 1) {
			pagesArray = [1, 0, activePageNumber - 1, activePageNumber, activePageNumber + 1];
		} else if (pageCount - activePageNumber >= activePageNumber - 1) {
			pagesArray = [activePageNumber - 1, activePageNumber, activePageNumber + 1, 0, pageCount];
		}

		setPages(pagesArray);
	};

	useEffect(() => {
		composePages();
	}, [activePage, itemsPerPage, totalCount]);

	if (itemsPerPage >= totalCount) return null;

	return (
		<ul id="pager" className="flex-col">
			{activePageNumber > 1 ? (
				<li className="arrow back" onClick={() => setPage(activePageNumber - 1)}>
					<img src={pagerArrowSVG} alt="pager arrow back" />
				</li>
			) : (
				<li className="arrow"></li>
			)}
			{pages.map(page => (
				<li key={page} className={activePageNumber == page ? 'active' : ''}>
					<button onClick={() => setPage(page)}>{page !== 0 ? page : <span className="dots">...</span>}</button>
				</li>
			))}
			{activePageNumber < totalPageCount ? (
				<li className="arrow front" onClick={() => setPage(activePageNumber + 1)}>
					<img src={pagerArrowSVG} alt="pager arrow front" />
				</li>
			) : (
				<li className="arrow"></li>
			)}
		</ul>
	);
};

Pager.propTypes = {
	totalCount: PropTypes.number,
	itemsPerPage: PropTypes.number,
	searchParams: PropTypes.any,
	setSearchParams: PropTypes.any,
	activePage: PropTypes.any
};

export default Pager;
