import { useEffect, useState } from 'react';

// Icons
import { api_getBannersByBannerGroupName } from '~/backend/api_calls';

export default function FooterTopBoxes() {
	const groupname = 'USPs';
	const [banners, setBanners] = useState([]);

	useEffect(() => {
		api_getBannersByBannerGroupName(groupname)
			.then(json => {
				setBanners(json.items);
			})
			.catch(error => {
				console.log(error);
			});
	}, []);

	return (
		<div id="footer-top-boxes">
			<div className="content-wrapper">
				<div className="footer-top-boxes-container">
					{banners.map((banner, index) => (
						<div key={index} className="border-linear">
							<div className="footer-box">
								<img src={banner.img1} alt="Shipping Icon" loading="lazy" />
								<h2 className="title">{banner.tl}</h2>
								<h3 className="subtitle">{banner.bd}</h3>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
