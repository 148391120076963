import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api_createWishlist } from '~/backend/api_calls';
import { notifyError, notifyInfo } from '~/components/common/ToastMessages';

import { useSelector } from 'react-redux';
import Button from '~/components/common/Button';
import { notifyInfoWishlist } from '~/components/common/WishlistMessage';
import closeFilters from '~/images/close-filters.svg';

const AddWishlist = ({ setShowAddWishlist }) => {
	const { t, i18n } = useTranslation();
	const [wishlistName, setWishlistName] = useState('');

	const closeAddToWishlist = useSelector(state => state.layoutReducer.showAddToWishlist);

	const handleFormSubmit = e => {
		e.preventDefault();

		if (wishlistName == null || wishlistName.length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		api_createWishlist(wishlistName)
			.then(json => {
				notifyInfoWishlist(t('notification_new_wishlist_added', { wishlistName }));
				closeWishlist();
			})
			.catch(error => {
				notifyInfo(error);
				console.error(error);
			});
	};

	const closeWishlist = () => {
		setShowAddWishlist(false);
		if (!closeAddToWishlist) {
			document.body.classList.remove('overflow');
		}
	};

	return (
		<div className="modal">
			<div className="modal-content add-wish-modal">
				<button className="exit" type="button">
					<img src={closeFilters} alt="exit button" onClick={() => closeWishlist()} />
				</button>
				<h2 className="flex-col">{t('add_new_wishlist_title')}</h2>
				<div className="new-wishlist-addition add-new-wish wish-info">
					<p>{t('add_new_wishlist_description')}</p>
					<form method="post" className="add-new-wishlist-form" onSubmit={handleFormSubmit}>
						<div className="inputs">
							<div className={wishlistName ? 'form-input text-label actived' : 'form-input text-label'}>
								<input
									type="text"
									id="name-wishlist"
									value={wishlistName}
									onChange={e => setWishlistName(e.target.value)}
									required
									placeholder="name"
								/>
								<label htmlFor="name-wishlist">{t('wishlist_input_label')}</label>
							</div>
						</div>
						<Button color="secondary">{t('save_button')}</Button>
					</form>
				</div>
			</div>
		</div>
	);
};

AddWishlist.propTypes = {
	setShowAddWishlist: PropTypes.any
};

export default AddWishlist;
