import { useEffect } from 'react';
import { useParams } from 'react-router';
import useFilters from './useFilters';

const ScrollRestoration = ({ debounceDelay = 150 }) => {
	const { page } = useFilters();
	let { eurl } = useParams();

	useEffect(() => {
		let scrollTimeout;

		const handleScroll = () => {
			clearTimeout(scrollTimeout);

			scrollTimeout = setTimeout(() => {
				const scrollPosition = window.scrollY + 100;
				const scrollData = {
					scrollPosition,
					eurl,
					page
				};
				sessionStorage.setItem('scrollData', JSON.stringify(scrollData));
			}, debounceDelay);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
			clearTimeout(scrollTimeout);
		};
	}, [eurl, page, debounceDelay]);

	return <></>;
};

ScrollRestoration.propTypes = {};
export default ScrollRestoration;
