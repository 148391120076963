import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductCardSizes = ({ product }) => {
	const { t } = useTranslation();

	if (product.sizes == null || product.sizes.length == 0) {
		return null;
	}

	return (
		<div className="sizes-container">
			<div className="sizes">
				{product.sizes
					.filter(s => s.qty > 0)
					.map((item, index) => (
						<span className={`size ${!item.qty || item.qty <= 0 ? 'disabled' : ''}`} key={product.id + '-' + index}>
							{item.svtxt}
						</span>
					))}
			</div>
		</div>
	);
};

ProductCardSizes.propTypes = {
	product: PropTypes.object
};
export default ProductCardSizes;
