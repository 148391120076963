import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Button from '~/components/common/Button';
import { setShowNotification } from '~/store/actions';

const NoSelectedGender = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<>
			<div className="header">
				<div className="title">
					{t('application_no_selected_gender')}
					<br />
				</div>
			</div>
			<div className="body"></div>
			<div className="footer">
				<Button
					color="secondary"
					actionOnClick={() => {
						dispatch(setShowNotification(false));
						navigate('/profile');
					}}>
					{t('my_profile')}
				</Button>
			</div>
		</>
	);
};

NoSelectedGender.propTypes = {};

export default NoSelectedGender;
