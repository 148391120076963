// import './App.css';
import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';
import { Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

/*import TagManager from 'react-gtm-module';*/

import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProtectedRoute } from './routes';

import HistoryMonitor from '~/components/common/HistoryMonitor';
import LanguageMonitor from '~/components/common/LanguageMonitor';
import LoadingSpinner from '~/components/common/LoadingSpinner';
import PageHeaderMeta from '~/components/common/PageHeaderMeta';
import ScrollToTop from '~/components/common/ScrollToTop';
import UpdateCartDB from '~/components/common/UpdateCartDB';
import UpdateCookieServices from '~/components/common/UpdateCookieServices';
import LayoutClassic from '~/components/layout/LayoutClassic';
import AddToWishlist from '~/components/wishlist/AddToWishlist';
import Application from '~/pages/Application';
import BlackFriday from '~/pages/BlackFriday';
import Impersonation from '~/pages/Customer/Impersonation';
import FindbarSearch from '~/pages/FindbarSearch';
import SearchPage from '~/pages/Search';
import StatusList from '~/pages/Status/StatusList';

// Lazy load page components
const Home = lazy(() => import('~/pages/Home'));
const Login = lazy(() => import('~/pages/Customer/Login'));
const Logout = lazy(() => import('~/pages/Customer/Logout'));
const Profile = lazy(() => import('~/pages/Customer/Profile'));
const Giftcard = lazy(() => import('~/pages/Giftcards/Giftcard'));
const Coupons = lazy(() => import('~/pages/Customer/Coupons'));
const Addresses = lazy(() => import('~/pages/Customer/Addresses'));
const AddressEdit = lazy(() => import('~/pages/Customer/AddressEdit'));
const AddressAdd = lazy(() => import('~/pages/Customer/AddressAdd'));
const OrderHistory = lazy(() => import('~/pages/Customer/OrderHistory/OrderHistory'));
const Order = lazy(() => import('~/pages/Customer/OrderHistory/Order'));
const FollowOrder = lazy(() => import('~/pages/Customer/FollowOrder'));
const ReturnOrder = lazy(() => import('~/pages/Customer/ReturnOrder'));
const ReturnOrderForm = lazy(() => import('~/pages/Customer/ReturnOrderForm'));
const CheckoutStep1 = lazy(() => import('~/pages/Checkout/CheckoutStep1'));
const CheckoutStep2 = lazy(() => import('~/pages/Checkout/CheckoutStep2'));
const CheckoutStep3 = lazy(() => import('~/pages/Checkout/CheckoutStep3'));
const CheckoutSuccess = lazy(() => import('~/pages/Checkout/CheckoutSuccess'));
const CheckoutFailure = lazy(() => import('~/pages/Checkout/CheckoutFailure'));
const RegistrationCheckEmail = lazy(() => import('~/pages/Customer/RegistrationCheckEmail'));
const RegistrationCheckSMS = lazy(() => import('~/pages/Customer/RegistrationCheckSMS'));
const NotFound = lazy(() => import('~/pages/NotFound'));
const OutOfStock = lazy(() => import('~/pages/OutOfStock'));
const Product = lazy(() => import('~/pages/Product'));
const ContentPage = lazy(() => import('~/pages/ContentPage'));
const CategoryList = lazy(() => import('~/pages/Category/CategoryList'));
const Wishlists = lazy(() => import('~/pages/Customer/Wishlists'));
const Wishlist = lazy(() => import('~/pages/Customer/Wishlist'));
const GuestWishlist = lazy(() => import('~/pages/Customer/GuestWishlist'));
const CustomerNavigationPage = lazy(() => import('~/pages/Customer/CustomerNavigationPage'));
const Cart = lazy(() => import('~/pages/Cart'));
const Clearance = lazy(() => import('~/pages/Clearance'));
const NewsList = lazy(() => import('~/pages/News/NewsList'));
const NewsCategory = lazy(() => import('~/pages/News/NewsCategory'));
const NewsEntry = lazy(() => import('~/pages/News/NewsEntry'));
const ValidateNewsletterSubscription = lazy(() => import('~/pages/ValidateNewsletterSubscription'));
const ValidateCustomerRegistration = lazy(() => import('~/pages/ValidateCustomerRegistration'));
const ResetPasswordRequest = lazy(() => import('~/pages/Customer/ResetPasswordRequest'));
const ResetPassword = lazy(() => import('~/pages/Customer/ResetPassword'));
const Sitemap = lazy(() => import('~/pages/Xml/Sitemap'));

// Viva Payments
const VivaSuccess = lazy(() => import('~/pages/VivaPayments/VivaSuccess'));
const VivaFailure = lazy(() => import('~/pages/VivaPayments/VivaFailure'));

// PayPal
const PayPalSuccess = lazy(() => import('~/pages/PayPal/PayPalSuccess'));
const PayPalCancel = lazy(() => import('~/pages/PayPal/PayPalCancel'));

// Alpha Cardlink
const AlphaCardlinkRequestForm = lazy(() => import('~/pages/AlphaBank/AlphaCardlinkRequestForm'));
const AlphaCardlinkSuccess = lazy(() => import('~/pages/AlphaBank/AlphaCardlinkSuccess'));
const AlphaCardlinkCancel = lazy(() => import('~/pages/AlphaBank/AlphaCardlinkCancel'));

const StoreRegister = lazy(() => import('~/pages/Customer/StoreRegister'));
const RegistrationApproved = lazy(() => import('~/pages/Customer/RegistrationApproved'));

function App() {
	UpdateCartDB();
	UpdateCookieServices();

	const showAddToWishlist = useSelector(state => state.layoutReducer.showAddToWishlist);
	const wishlistProductId = useSelector(state => state.layoutReducer.wishlistProductId);

	const createAppRoutes = () => {
		return (
			<>
				<Route index element={<Home />} />

				{/* {environment === 'staging' && <Route path="black-friday-phase-2" element={<BlackFriday />} />} */}

				<Route path="black-friday" element={<BlackFriday />} />

				<Route path="pages/:eurl" element={<ContentPage />} />

				<Route path="products/:eurl" element={<Product />} />

				<Route path="categories/:eurl" element={<CategoryList />} />

				<Route path="status/:eurl" element={<StatusList />} />

				<Route path="giftcard" element={<Giftcard />} />

				<Route path="login" element={<Login />} />
				<Route path="logout" element={<Logout />} />

				<Route path="clearance" element={<Clearance />} />

				<Route path="cart" element={<Cart />} />

				<Route path="out-of-stock" element={<OutOfStock />} />

				<Route path="blog" element={<NewsList />} />
				<Route path="blog/:eurl" element={<NewsCategory />} />
				<Route path="blogPost/:eurl" element={<NewsEntry />} />

				<Route path="checkout-step-1" element={<CheckoutStep1 />} />
				<Route path="checkout-step-2" element={<CheckoutStep2 />} />
				<Route path="checkout-step-3" element={<CheckoutStep3 />} />
				<Route path="checkout-success/:uid" element={<CheckoutSuccess />} />
				<Route path="checkout-failure" element={<CheckoutFailure />} />

				<Route path="registrationcheckemail" element={<RegistrationCheckEmail />} />
				<Route path="register-check-sms" element={<RegistrationCheckSMS />} />

				<Route path="wishlist/:id" element={<Wishlist />} />
				<Route path="wishlist" element={<GuestWishlist />} />

				{/* <Route path="validatenewslettersubscription/:guid" element={<ValidateNewsletterSubscription />} /> */}
				<Route path="newsletter-subscription-verified" element={<ValidateNewsletterSubscription />} />
				<Route path="validateregistration/:guid" element={<ValidateCustomerRegistration />} />

				<Route path="resetpasswordrequest" element={<ResetPasswordRequest />} />
				<Route path="resetpassword/:guid" element={<ResetPassword />} />

				<Route path="payment/viva/success" element={<VivaSuccess />} />
				<Route path="payment/viva/failure" element={<VivaFailure />} />

				<Route path="payment/paypal/success" element={<PayPalSuccess />} />
				<Route path="payment/paypal/cancel" element={<PayPalCancel />} />

				<Route path="payment/alpha_request/:id" element={<AlphaCardlinkRequestForm />} />
				<Route path="payment/alpha/success/:uid" element={<AlphaCardlinkSuccess />} />
				<Route path="payment/alpha/cancel" element={<AlphaCardlinkCancel />} />

				<Route path="impersonate/:guid" element={<Impersonation />} />

				<Route path="search" element={<SearchPage />} />
				<Route path="search-results" element={<FindbarSearch />} />

				<Route path="register-store/:id?" element={<StoreRegister />} />
				<Route path="registration-approved" element={<RegistrationApproved />} />
				<Route path="followorder" element={<FollowOrder />} />
				<Route path="followorder/:uid" element={<FollowOrder />} />
				<Route path="return-order" element={<ReturnOrderForm />} />
				<Route path="return-order/:uid" element={<ReturnOrder />} />

				<Route element={<ProtectedRoute />}>
					<Route path="application" element={<Application />} />
					<Route path="profile" element={<Profile />} />
					<Route path="coupons" element={<Coupons />} />
					<Route path="addresses" element={<Addresses />} />
					<Route path="address/edit/:id" element={<AddressEdit />} />
					<Route path="address/add" element={<AddressAdd />} />
					<Route path="orders" element={<OrderHistory />} />
					<Route path="order/:uid" element={<Order />} />
					<Route path="wishlists" element={<Wishlists />} />
					<Route path="navigation" element={<CustomerNavigationPage />} />
				</Route>

				<Route path="*" element={<NotFound />} />
			</>
		);
	};

	return (
		<div className="App">
			<Router>
				<HistoryMonitor />
				<LanguageMonitor />
				<PageHeaderMeta />
				<ScrollToTop />
				<Suspense fallback={<LoadingSpinner isLoading={true} />}>
					<Routes>
						<Route
							path="/"
							element={
								<LayoutClassic>
									<Outlet />
								</LayoutClassic>
							}>
							{createAppRoutes()}
						</Route>

						<Route
							path="/el"
							element={
								<LayoutClassic>
									<Outlet />
								</LayoutClassic>
							}>
							{createAppRoutes()}
						</Route>

						<Route
							path="/en"
							element={
								<LayoutClassic>
									<Outlet />
								</LayoutClassic>
							}>
							{createAppRoutes()}
						</Route>

						<Route path="/sitemap.xml" element={<Sitemap />} />
					</Routes>
				</Suspense>
			</Router>

			<ToastContainer className="toast-content" />
			{showAddToWishlist == true ? <AddToWishlist productId={wishlistProductId} /> : null}

			{/* <CookiesBanner /> */}
		</div>
	);
}

App.propTypes = {
	showAddToWishlist: PropTypes.bool,
	wishlistProductId: PropTypes.number
};

export default App;
