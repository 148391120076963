import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '~/components/common/Button';
import { setShowNotification } from '~/store/actions';
import { percentageFormat } from '../../../../utils/percentageFormat';
import { useApplicationDiscount } from '../useApplicationDiscount';
const ShopForMe = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { discount, isLoading, error } = useApplicationDiscount('me');
	if (isLoading) return null;
	if (error || discount == null) return null;
	return (
		<>
			<div className="header">
				<div className="title">
					{t('application_from_now_on')} <br />
					<span>{t('application_yourself')}</span>
					<span>
						{' '}
						{t('application_with_discount')} {percentageFormat.format(discount / 100)}
					</span>
				</div>
			</div>
			<div className="body"></div>
			<div className="footer">
				<Button color="secondary" actionOnClick={() => dispatch(setShowNotification(false))}>
					{t('close')}
				</Button>
			</div>
		</>
	);
};

ShopForMe.propTypes = {};

export default ShopForMe;
