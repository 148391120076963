import PropTypes from 'prop-types';
import React from 'react';

const Checkbox = ({ label = '', checked = false, onChange = () => {} }) => {
	return (
		<label className="custom-checkbox">
			{label}
			<input type="checkbox" checked={checked} onChange={onChange} onClick={e => e.stopPropagation()} />
			<span className="checkmark"></span>
		</label>
	);
};

Checkbox.propTypes = {
	label: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func
};

export default Checkbox;
