export const validateGuestUserEmail = email => {
	if (email == null || email.trim().length == 0) return false;

	const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/;
	if (!emailRegex.test(email)) {
		return false;
	}

	return true;
};
