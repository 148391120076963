export const loadFindbarScript = () => {
	if (process.env.REACT_APP_FINDBAR_ENABLED === 'true') {
		const lang = localStorage.getItem('I18N_LANGUAGE') === 'en' ? 'en' : 'el';
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = lang === 'el' ? process.env.REACT_APP_FINDBAR_URL_EL : process.env.REACT_APP_FINDBAR_URL_EN;
		script.async = true;
		document.body.appendChild(script);
	}
};

export const loadChatlingScript = () => {
	if (process.env.REACT_APP_CHATLING_ENABLED === 'true') {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://chatling.ai/js/embed.js';
		script.id = 'chatling-embed-script';
		script.setAttribute('data-id', '9415273499');
		script.async = true;
		document.body.appendChild(script);
	}
};

const loadCustomScripts = () => {
	// # Example
	// if (process.env.REACT_APP_INCLUDE_CUSTOM_SCRIPT === 'true') {
	// 	const script = document.createElement('script');
	// 	script.type = 'text/javascript';
	// 	script.src = 'path/to/custom-script.js';
	// 	script.async = true;
	// 	document.body.appendChild(script);
	// }

	if (process.env.REACT_APP_CONTACT_PIGEON_ENABLED === 'true') {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = process.env.REACT_APP_CONTACT_PIGEON_URL;
		script.async = true;
		document.body.appendChild(script);
	}

	loadFindbarScript();
	loadChatlingScript();
};

export default loadCustomScripts;
