import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useFilterState = () => {
	const location = useLocation();
	return useMemo(() => {
		var params = new URLSearchParams(location.search);
		return {
			filtersUrl: params.get('q'),
			sortingOption: params.get('sort'),
			viewPerPageOption: params.get('psize') || 24,
			page: params.get('page') || 1
		};
	}, [location.search]);
};
