import { Link } from 'react-router-dom';

const BrandsLayout = ({ parentMenuItem, menuItems }) => {
	return (
		<>
			{menuItems
				.filter(x => x.pid === parentMenuItem.id)
				.map((item, index) => (
					<div key={index} className="brand brands-layout">
						<div className="image-menu-item">
							<Link to={item.nu}>
								<img src={item.img1} alt={item.tl} />
							</Link>
						</div>
					</div>
				))}
		</>
	);
};

export default BrandsLayout;
