import { apiBaseUrl, del, get, getRaw, post, postRaw, put } from '~/backend/api_helper';
// customer auth calls
import axios from 'axios';
import UserState from '~/backend/user_session';

// customer auth calls
export const api_login = data => post(apiBaseUrl() + '/customers/login', data, false);
export const api_googleLogin = data => post(apiBaseUrl() + '/customers/googlelogin', data, false);
export const api_facebookLogin = data => post(apiBaseUrl() + '/customers/facebooklogin', data, false);
export const api_logout = data => post(apiBaseUrl() + '/customers/logout', data, false);
export const api_refreshToken = data => post(apiBaseUrl() + '/customers/refreshtoken', data, false);

export async function api_impersonateCustomer(guid) {
	const url = apiBaseUrl() + '/customers/impersonatecustomer?guid=' + guid;
	const response = await get(url, true, false);
	return response;
}

export async function api_registerCustomer(record) {
	const url = apiBaseUrl() + '/customers/register';
	const response = await post(url, record, false);
	return response;
}

export async function api_googleRegisterCustomer(record) {
	const url = apiBaseUrl() + '/customers/googleregister';
	const response = await post(url, record, false);
	return response;
}

export async function api_facebookRegisterCustomer(record) {
	const url = apiBaseUrl() + '/customers/facebookregister';
	const response = await post(url, record, false);
	return response;
}

export async function api_verifyCustomerRegistration(guid) {
	const url = apiBaseUrl() + '/customers/verifyregistration?guid=' + guid;
	const response = await get(url, false, false);
	return response;
}

export async function api_getCustomMessageByCode(code) {
	const url = apiBaseUrl() + '/custommessages/getbycode?code=' + code;
	const response = await get(url, false, false);
	return response;
}

export async function api_getProfile() {
	const url = apiBaseUrl() + '/customers/getprofile';
	const response = await get(url, true);
	return response;
}

export async function api_updateProfile(args) {
	const url = apiBaseUrl() + '/customers/updateprofile';
	const response = await post(url, args, true);
	return response;
}

export async function api_updatePassword(args) {
	const url = apiBaseUrl() + '/customers/updatepassword';
	const response = await post(url, args, true);
	return response;
}

export async function api_getAllCustomerDimensionValues() {
	const url = apiBaseUrl() + '/customers/getallcustomerdimensionvalues';
	const response = await get(url, true, true);
	return response;
}

export async function api_getCustomerDimensionValues() {
	const url = apiBaseUrl() + '/customers/getcustomerdimensionvalues';
	const response = await get(url, true, true);
	return response;
}

export async function api_updateCustomerDimensionValues(data) {
	const url = apiBaseUrl() + '/customers/updatecustomerdimensionvalues';
	const response = await put(url, data, true);
	return response;
}

export async function api_getProductByUrl(key) {
	const url = apiBaseUrl() + '/products/getbyurl?url=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getCategoryByUrl(key) {
	const url = apiBaseUrl() + '/categories/getbyurl?url=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getStatusByUrl(key) {
	const url = apiBaseUrl() + '/statuses/getbyurl?url=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getCategoryByCode(code) {
	const url = apiBaseUrl() + '/categories/getbycode?code=' + code;
	const response = await get(url, true, false);
	return response;
}

export async function api_getCategoryById(key) {
	const url = apiBaseUrl() + '/categories/getbyid?id=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getTopCategories() {
	const url = apiBaseUrl() + '/categories/gettopcategories';
	const response = await get(url, true, false);
	return response;
}

export async function api_getProductsByCategoryId(key, filter = '', sort = '', page = 0, psize = 0) {
	var qstr = '?id=' + encodeURIComponent(key);

	if (filter != null && filter.length > 0) {
		qstr += '&q=' + encodeURIComponent(filter);
	}
	if (sort != null && sort.length > 0) {
		qstr += '&sort=' + encodeURIComponent(sort);
	}
	if (page != null && page > 0) {
		qstr += '&page=' + encodeURIComponent(page);
	}
	if (psize != null && psize > 0) {
		qstr += '&psize=' + encodeURIComponent(psize);
	}

	const url = apiBaseUrl() + '/products/getbycategoryid' + qstr;
	const response = await get(url, true, false);
	return response;
}

export async function api_getSizesByCategoryId(categoryId) {
	const url = apiBaseUrl() + '/products/getsizesbycategoryid?id=' + categoryId;
	const response = await get(url, true, false);
	return response;
}

export async function api_getFiltersByCategoryAndStatusId(key, statusId, filter = '') {
	var qstr = '';
	if (filter != null && filter.length > 0) {
		qstr += '&q=' + encodeURIComponent(filter);
	}

	const url = apiBaseUrl() + '/products/getfiltersbycategoryandstatusId?id=' + encodeURIComponent(key) + '&statusId=' + statusId + qstr;
	const response = await get(url, true, true);
	return response;
}

export async function api_getPersonalizedProductsIdsByCategoryId(categoryId, numberOfProducts = 6) {
	const url =
		apiBaseUrl() + '/products/getpersonalisedproductsbycategory?categoryId=' + categoryId + '&numberOfProducts=' + numberOfProducts;
	const response = await get(url, true, true);
	return response;
}

export async function api_getFeaturedProducts() {
	const url = apiBaseUrl() + '/products/getfeaturedproducts';
	const response = await get(url, true, false);
	return response;
}

export async function api_getGiftcardProducts() {
	const url = apiBaseUrl() + '/products/getgiftcards';
	const response = await get(url, true, false);
	return response;
}

export async function api_getByParentCategoryId(key) {
	const url = apiBaseUrl() + '/categories/getByParentCategoryId?parentid=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getBannersByBannerGroupName(key) {
	const url = apiBaseUrl() + '/banners/getbybannergroupname?bannergroupname=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getFaqsByEntityType(entityType, entityId) {
	const url = apiBaseUrl() + '/faqs/getbyentitytype?type=' + entityType + '&id=' + entityId;
	const response = await get(url, true, false);
	return response;
}

export async function api_getManufacturers() {
	const url = apiBaseUrl() + '/manufacturers/getallpublic';
	const response = await get(url, true, false);
	return response;
}

export async function api_getCountries(signal = null) {
	const url = apiBaseUrl() + '/countries/getallfront';
	const response = await get(url, true, false, signal);
	return response;
}

export async function api_getCountryRegions(cid) {
	const url = apiBaseUrl() + '/countryregions/getbycountry?cid=' + cid;
	const response = await get(url, true, false);
	return response;
}

export async function api_getCitiesByCountryRegion(crid) {
	const url = apiBaseUrl() + '/cities/getbycountryregion?crid=' + crid;
	const response = await get(url, true, false);
	return response;
}

export async function api_getStores() {
	const url = apiBaseUrl() + '/takeawaystores/getallfront';
	const response = await get(url, true, false);
	return response;
}

export async function api_findTakeAwayStores(cid = 0, rid = 0) {
	var suffix = '';

	if (cid > 0) suffix += '?cid=' + cid;
	if (rid > 0) suffix += (suffix.length > 0 ? '&' : '?') + 'rid=' + rid;

	const url = apiBaseUrl() + '/takeawaystores/findfront' + suffix;
	const response = await get(url, true, false);
	return response;
}

export async function api_findTakeAwayStoresGrouped(cid = 0) {
	var suffix = '';

	if (cid > 0) suffix += '?cid=' + cid;

	const url = apiBaseUrl() + '/takeawaystores/findfrontgroupedlookup' + suffix;
	const response = await get(url, true, false);
	return response;
}

export async function api_findTakeAwayStoreById(id) {
	const url = apiBaseUrl() + '/takeawaystores/findstorebyid?id=' + id;
	const response = await get(url, true, false);
	return response;
}

export async function api_getShippingTypes(signal = null) {
	const url = apiBaseUrl() + '/shippingtypes/getallfront';
	const response = await get(url, true, false, signal);
	return response;
}

export async function api_getTaxOffices(signal = null) {
	const url = apiBaseUrl() + '/taxoffices/getallfront';
	const response = await get(url, true, false, signal);
	return response;
}

export async function api_getAFMDetails(vat_number, signal = null) {
	const url = apiBaseUrl() + '/orders/getafmdetails?afm=' + vat_number;
	const response = await get(url, true, false, signal);
	return response;
}

export async function api_getPostalCodes(signal = null) {
	const url = apiBaseUrl() + '/postalcodes/getallfront';
	const response = await get(url, true, false, signal);
	return response;
}

export async function api_getPostalCodeByCode(code, signal = null) {
	const url = apiBaseUrl() + '/postalcodes/getbycode?code=' + code;
	const response = await get(url, true, false, signal);
	return response;
}

export async function api_getPaymentTypes(signal = null) {
	const url = apiBaseUrl() + '/paymenttypes/getallfront';
	const response = await get(url, true, false);
	return response;
}

export async function api_getPaymentTypesByOrder(args) {
	const url = apiBaseUrl() + '/paymenttypes/getallbyorder';
	const response = await post(url, args, false);
	return response;
}

export async function api_getPaymentTypesByShipping(sid) {
	const url = apiBaseUrl() + '/paymenttypes/getbyshippingtype?sid' + sid;
	const response = await get(url, true, false);
	return response;
}

export async function api_saveOrder(record) {
	const url = apiBaseUrl() + '/orders/save';
	const response = await post(url, record, false);
	return response;
}

export async function api_returnOrder(record) {
	const url = apiBaseUrl() + '/orders/returnorder';
	const response = await post(url, record, true);
	return response;
}

export async function api_getContentPageByCode(key) {
	const url = apiBaseUrl() + '/contentpages/getbycode?code=' + key;
	const response = await get(url, true, false);
	return response;
}

export async function api_getContentPageSidebarLinks() {
	const url = apiBaseUrl() + '/contentpages/getsiderbarlinks';
	const response = await get(url, true, false);
	return response;
}

export async function api_getContentPageMenuLinks(record) {
	const url = apiBaseUrl() + '/contentpages/getmenulinks?args=' + encodeURIComponent(JSON.stringify(record));
	const response = await get(url, true, false);
	return response;
}

export async function api_getContentPageByUrl(key) {
	const url = apiBaseUrl() + '/contentpages/getbyurl?url=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getProductColors(key) {
	const url = apiBaseUrl() + '/productdimensions/getproductvalues?dt=1&pid=' + key;
	const response = await get(url, true, false);
	return response;
}

export async function api_getProductSizes(key) {
	const url = apiBaseUrl() + '/productdimensions/getproductvalues?dt=2&pid=' + key;
	const response = await get(url, true, false);
	return response;
}

export async function api_getProductDimensions(key, signal = null) {
	const url = apiBaseUrl() + '/productdimensions/getdimensions?pid=' + key;
	const response = await get(url, true, false, signal);
	return response;
}

export async function api_getProductDimensionsValuesByDimensions(key) {
	const url = apiBaseUrl() + '/dimensionvalues/getbydimensionid?id=' + key;
	const response = await get(url, true, false);
	return response;
}

export async function api_getProductLoyaltyPoints(id, price, priceBD, discount) {
	const url = apiBaseUrl() + `/products/getproductloyaltypoints?id=${id}&price=${price}&priceBD=${priceBD}&discount=${discount}&`;
	const response = await get(url, true, false);
	return response;
}

export async function api_vivaParseTransaction(record) {
	const url = apiBaseUrl() + '/vivapayments/parsetransaction';
	const response = await post(url, record, false);
	return response;
}

export async function api_paypalParseTransaction(record) {
	const url = apiBaseUrl() + '/paypal/parsetransaction';
	const response = await post(url, record, false);
	return response;
}

export async function api_getWishlists() {
	const url = apiBaseUrl() + '/wishlists/GetAllByCustomer';
	const response = await get(url);
	return response;
}

export async function api_getWishlist(id) {
	const url = apiBaseUrl() + '/wishlists/getbyid?id=' + id;
	const response = await get(url);
	return response;
}

export async function api_createWishlist(data) {
	const url = apiBaseUrl() + '/wishlists/insert';
	const response = await post(url, { nm: data }, true);
	return response;
}

export async function api_updateWishlist(data) {
	const url = apiBaseUrl() + '/wishlists/update';
	const response = await put(url, data, true);
	return response;
}

export async function api_deleteWishlist(id) {
	const url = apiBaseUrl() + '/wishlists/delete?id=' + id;
	const response = await del(url);
	return response;
}

export async function api_addToWishlist(data) {
	const url = apiBaseUrl() + '/wishlists/addtowishlist';
	const response = await post(url, data, true);
	return response;
}

export async function api_removeFromWishlist(data) {
	const url = apiBaseUrl() + '/wishlists/removefromwishlist';
	const response = await post(url, data, true);
	return response;
}

export async function api_getProductTabs() {
	const url = apiBaseUrl() + '/producttabs/getallpublic';
	const response = await get(url, true);
	return response;
}

export async function api_getProductsByIds(data) {
	const url = apiBaseUrl() + '/products/getbyproductsids?productsids=' + data;
	const response = await get(url, true);
	return response;
}

export async function api_composeCartTotals(args, signal = null, couponscodes = [], gfcd = [], r_lpnt = 0, metaData = {}) {
	const url = apiBaseUrl() + '/orders/composecarttotals';

	// Application related
	const applicationMeta = {};
	if (
		metaData.isApplicationUser &&
		metaData.hasAcceptedApplicationTerms &&
		process.env.REACT_APP_SPORTISTAS_APPLICATION_ENABLED == 'true'
	) {
		applicationMeta.sportistas_applmode = metaData?.mode;
	}

	const response = await post(
		url,
		{
			items: args,
			eml: UserState.getEmail(),
			couponscodes: couponscodes,
			gfcd: gfcd,
			r_lpnt: r_lpnt,
			rt: metaData?.rt || 0,
			ctid: metaData.ctid || 1,
			pc: metaData?.pc || null,
			...applicationMeta
		},
		true,
		signal
	);

	return response;
}

export async function api_composeOrderTotals(args, signal = null) {
	const url = apiBaseUrl() + '/orders/composeordertotals';
	const response = await post(url, args, false, signal);
	return response;
}

export async function api_getShippingChargeByCountry(id) {
	const url = apiBaseUrl() + '/shippingcharges/getbycountry?countryId=' + id;
	const response = await get(url, true, false);
	return response;
}

export async function api_getByShowFront() {
	const url = apiBaseUrl() + '/newsentries/getbyshowfront';
	const response = await get(url, true, false);
	return response;
}

export async function api_getAllEntries(pageIndex = 1, pageSize = 5) {
	const url = apiBaseUrl() + '/newsentries/getallentries?pageIndex=' + pageIndex + '&pageSize=' + pageSize;
	const response = await get(url, true, false);
	return response;
}

export async function api_getAllNewsCategories() {
	const url = apiBaseUrl() + '/newscategories/getallcategories';
	const response = await get(url, true, false);
	return response;
}

export async function api_getNewsCategoryByUrl(key) {
	const url = apiBaseUrl() + '/newscategories/getbyurl?url=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getNewsEntriesByNewsCategoryId(id, pageIndex = 1, pageSize = 5) {
	const url = apiBaseUrl() + '/newsentries/getbynewscategoryid?id=' + id + '&pageIndex=' + pageIndex + '&pageSize=' + pageSize;
	const response = await get(url, id, false);
	return response;
}

export async function api_getNewsEntryByUrl(key) {
	const url = apiBaseUrl() + '/newsentries/getbyurl?url=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getAddressesByCustomerId(id) {
	const url = apiBaseUrl() + '/customeraddresses/getbycustomerid?id=' + id;
	const response = await get(url);
	return response;
}

export async function api_deleteAddressByCustomer(id) {
	const url = apiBaseUrl() + '/customeraddresses/deletebycustomer?id=' + id;
	const response = await del(url, id, true);
	return response;
}

export async function api_updateAddressByCustomer(args) {
	const url = apiBaseUrl() + '/customeraddresses/updatebycustomer';
	const response = await put(url, args, true);
	return response;
}

export async function api_insertAddressByCustomer(args) {
	const url = apiBaseUrl() + '/customeraddresses/insertbycustomer';
	const response = await post(url, args, true);
	return response;
}

export async function api_getAddressByCustomer(id) {
	const url = apiBaseUrl() + '/customeraddresses/getaddressbycustomer?id=' + id;
	const response = await get(url, true, true);
	return response;
}

export async function api_getAddressById(id) {
	const url = apiBaseUrl() + '/customeraddresses/getbyid?id=' + id;
	const response = await get(url, true, true);
	return response;
}

export async function api_getOrdersByCustomer(id) {
	const url = apiBaseUrl() + '/orders/getbycustomerid?id=' + id;
	const response = await get(url, false, true);
	return response;
}

export async function api_getOrderProductsByOrder(id) {
	const url = apiBaseUrl() + '/orderlines/getbyorderid?id=' + id;
	const response = await get(url, false, true);
	return response;
}

export async function api_getOrderByCustomer(uid, email) {
	const url = apiBaseUrl() + `/orders/getorderbycustomer?uid=${uid}&email=${email}`;
	const response = await get(url, false);
	return response;
}

export async function api_getGiftcardOrderDetails(id) {
	const url = apiBaseUrl() + `/orders/getgiftcardorderdetails?id=${id}`;
	const response = await get(url, false);
	return response;
}

export async function api_getOrderType(uid, email) {
	const url = apiBaseUrl() + `/orders/GetOrderType?uid=${uid}&email=${email}`;
	const response = await get(url, false);
	return response;
}

export async function api_getOrderStatusChangesByOrderUID(uid) {
	const url = apiBaseUrl() + `/orders/GetOrderStatusChangesByOrderUID?uid=${uid}`;
	const response = await get(url, false, true);
	return response;
}

export async function api_getOrderLoyaltyPoints(id) {
	const url = apiBaseUrl() + `/orders/GetOrderLoyaltyPoints?id=${id}`;
	const response = await get(url, false, true);
	return response;
}

export async function api_subscribeToNewsletter(email) {
	const url = apiBaseUrl() + '/newslettersubscriptions/subscribe?email=' + email;
	const response = await get(url, true, false);
	return response;
}

export async function api_verifyNewsletterSubscription(guid) {
	const url = apiBaseUrl() + '/newslettersubscriptions/verifysubscription?guid=' + guid;
	const response = await get(url, true, false);
	return response;
}

export async function api_resetPasswordRequest(email) {
	const url = apiBaseUrl() + '/customers/resetpasswordrequest?email=' + email;
	const response = await get(url, true, false);
	return response;
}

export async function api_getCustomerOfPasswordResetRequest(guid) {
	const url = apiBaseUrl() + '/customers/getcustomerofpasswordresetrequest?guid=' + guid;
	const response = await get(url, true, false);
	return response;
}

export async function api_resetPassword(args) {
	const url = apiBaseUrl() + '/customers/resetpassword';
	const response = await post(url, args, false);
	return response;
}

export async function api_getCollectionProductsByProductId(productId) {
	const url = apiBaseUrl() + '/products/getrelatedproductsbyproductId?productId=' + productId + '&relationTypeId=1';
	const response = await get(url, true, false);
	return response;
}

export async function api_getApplicationDiscount(mode) {
	if (UserState.isAuthenticated()) {
		const url = apiBaseUrl() + '/Sportistas/Customers/GetApplicationDiscounts?mode=' + mode;
		const response = await get(url, false, true);
		return response;
	}

	return null;
}

export async function api_searchProducts({ enrich = false, keyword = '', pageIndex = 0, pageSize = 0, categoryIds = '', status = 0 }) {
	let qstr = '?enrich=' + encodeURIComponent(enrich);
	if (keyword !== '') {
		qstr += '&q=' + encodeURIComponent(keyword);
	}
	if (pageIndex !== 0) {
		qstr += '&page=' + encodeURIComponent(pageIndex);
	}
	if (pageSize !== 0) {
		qstr += '&psize=' + encodeURIComponent(pageSize);
	}
	if (categoryIds !== '') {
		qstr += '&cids=' + encodeURIComponent(categoryIds);
	}
	if (status !== 0) {
		qstr += '&stids=' + encodeURIComponent(status);
	}
	const url = apiBaseUrl() + '/products/search' + qstr;
	const response = await get(url, true, false);
	return response;
}

export async function api_searchProductsWithFilters(keyword, statusId = 0, filter = '', sort = '', page = 0, psize = 0) {
	var qstr = '?key=';

	if (keyword != null && keyword.length > 0) {
		qstr += encodeURIComponent(keyword);
	}
	if (statusId != null && statusId > 0) {
		qstr += '&stids=' + statusId;
	}
	if (filter != null && filter.length > 0) {
		qstr += '&q=' + encodeURIComponent(filter);
	}
	if (sort != null && sort.length > 0) {
		qstr += '&sort=' + encodeURIComponent(sort);
	}
	if (page != null && page > 0) {
		qstr += '&page=' + encodeURIComponent(page);
	}
	if (psize != null && psize > 0) {
		qstr += '&psize=' + encodeURIComponent(psize);
	}

	const url = apiBaseUrl() + '/products/searchwithfilters' + qstr;
	const response = await get(url, true, false);
	return response;
}

export async function api_searchCategories(keyword) {
	const url = apiBaseUrl() + '/categories/search?q=' + keyword + '&psize=5';
	const response = await get(url, true, false);
	return response;
}

export async function api_searchProductsFull(keyword) {
	const url = apiBaseUrl() + '/products/search?q=' + keyword + '&enrich=true';
	const response = await get(url, true, false);
	return response;
}

export async function api_sendContactMail(args) {
	const url = apiBaseUrl() + '/contactformentries/composecontactmail';
	const response = await post(url, args, false);
	return response;
}

export async function api_getAlphaCardlinkRequest(id) {
	const url = apiBaseUrl() + '/alphacardlink/getrequestbyid?id=' + id;
	const response = await get(url, false, false);
	return response;
}

export async function api_getMainMenu() {
	const url = apiBaseUrl() + '/menuitems/getmainmenu';
	const response = await get(url, true, false);
	return response;
}

export async function api_addCartItem(args) {
	const url = apiBaseUrl() + '/cartitems/addtocart';
	const response = await post(url, args, true);
	return response;
}

export async function api_removeCartItem(args) {
	const url = apiBaseUrl() + '/cartitems/addtocart';
	const response = await post(url, args, true);
	return response;
}

export async function api_getCustomerCartItems(args) {
	const url = apiBaseUrl() + '/cartitems/getbycustomerid';
	const response = await get(url, false, true);
	return response;
}

export async function api_updateCustomerDBCart(args) {
	const url = apiBaseUrl() + '/cartitems/updateCustomerCart';
	const response = await post(url, args, true);
	return response;
}

export async function api_validateCoupon(coupon) {
	const url = apiBaseUrl() + '/customercoupons/validatecoupon?code=' + coupon;
	const response = await get(url, false, true);
	return response;
}

export async function api_validateAndActivateCoupon(code, email) {
	const url = apiBaseUrl() + '/Coupons/Validate';
	const response = await postRaw(url, { couponCode: code, email }, true, false);
	return response;
}

export async function api_validateCouponGuest(coupon) {
	const url = apiBaseUrl() + '/coupons/validatecoupon?code=' + coupon;
	const response = await get(url, false, true);
	return response;
}

export async function api_getLoyaltyPointsBalance(args) {
	const url = apiBaseUrl() + '/customers/getloyaltypointsbalance';
	const response = await post(url, false, true);
	return response;
}

export async function api_couponsGetForCustomer(args) {
	const url = apiBaseUrl() + '/customercoupons/getforcustomer';
	const response = await get(url, false, true);
	return response;
}

export async function api_getGiftAmount(code) {
	const url = apiBaseUrl() + '/products/getgiftamount?code=' + code;
	const response = await get(url, false, false);
	return response;
}

export async function api_getBusinessCode() {
	const url = apiBaseUrl() + '/customers/getbusinesscode';
	const response = await getRaw(url, false, true);
	if (response.data) {
		return response.data;
	} else {
		throw response;
	}
}

export async function api_saveBusinessCode(code) {
	const url = apiBaseUrl() + '/customers/savebusinesscode?businessCode=' + code;
	const response = await getRaw(url, false, true);
	if (response.data) {
		return response.data;
	} else {
		throw response;
	}
}

export async function api_getSkroutzXML() {
	const url = apiBaseUrl() + '/products/getskroutzxml';
	const response = await get(url, false, true);
	return response;
}

export async function api_getSitemapXML() {
	const url = apiBaseUrl() + '/sitemap/index';
	const response = await get(url, false, true);
	return response;
}

export async function api_getSettingValue(name) {
	const url = apiBaseUrl() + '/settings/getvalue?name=' + name;
	const response = await get(url, false, false);
	return response;
}

export async function api_getCustomerLoyaltyPointRanges(totalPrice) {
	const url = apiBaseUrl() + '/orders/getcustomerloyaltypointranges?totalPrice=' + totalPrice;
	const response = await get(url, true, true);
	return response;
}

export async function api_getCustomerAvailableLoyaltyPoints() {
	const url = apiBaseUrl() + '/customers/getcustomeravailableloyaltypoints';
	const response = await get(url, true, true);
	return response;
}

export async function api_getCustomerMeta(customerId, key, opts) {
	if (!(customerId > 0)) throw new Error('Invalid customer id: ' + customerId);
	let url = `${apiBaseUrl()}/customers/getmeta/${customerId}/${key}`;
	if (opts?.group) {
		url += `?group=${opts.group}`;
	}
	// eslint-disable-next-line no-unused-vars
	const { key: _, group: __, value } = await get(url, true, true);
	return value ?? null;
}

export async function api_setCustomerMeta(customerId, key, data, opts) {
	if (!(customerId > 0)) throw new Error('Invalid customer id: ' + customerId);
	let url = `${apiBaseUrl()}/customers/setmeta/${customerId}/${key}`;
	if (opts?.group) {
		url += `?group=${opts.group}`;
	}
	// eslint-disable-next-line no-unused-vars
	return (await post(url, data, true)) ?? null;
}

export async function api_getCurrentCustomerMeta(key, opts) {
	let url = `${apiBaseUrl()}/Customers/GetCurrentCustomerMeta/${key}`;
	if (opts?.group) {
		url += `?group=${opts.group}`;
	}
	// eslint-disable-next-line no-unused-vars
	const { key: _, group: __, value } = await get(url, true, true);
	return value ?? null;
}

export async function api_setCurrentCustomerMeta(key, data, opts) {
	let url = `${apiBaseUrl()}/Customers/SetCurrentCustomerMeta/${key}`;
	if (opts?.group) {
		url += `?group=${opts.group}`;
	}
	// eslint-disable-next-line no-unused-vars
	return (await post(url, data, true)) ?? null;
}

// Contact Pigeon Integration Api
export async function api_contactPigeonUpdateProfile(jsonPayload) {
	const contactPigeonBaseApiUrl = 'https://ping.contactpigeon.com/bi/api/3/';

	jsonPayload.api_key = process.env.REACT_APP_CONTACT_PIGEON_API_KEY;

	try {
		const formData = new FormData();
		formData.append('payload', JSON.stringify(jsonPayload));

		const response = await axios.post(contactPigeonBaseApiUrl, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function api_validateStoreCustomer(record) {
	const url = apiBaseUrl() + '/sportistas/customers/validate';
	const response = await post(url, record, false);
	return response;
}

export async function api_registerStoreCustomer(record) {
	const url = apiBaseUrl() + '/sportistas/customers/register';
	const response = await post(url, record, false);
	return response;
}

export async function api_approveStoreCustomer(record) {
	const url = apiBaseUrl() + '/sportistas/customers/approve';
	const response = await post(url, record, false);
	return response;
}

export async function api_orderSearch(args) {
	const url = apiBaseUrl() + '/Orders/OrderSearch';
	const response = await post(url, args, false);
	return response;
}

export async function api_orderReturn(args) {
	const url = apiBaseUrl() + '/Orders/OrderReturn';
	const response = await post(url, args, false);
	return response;
}
