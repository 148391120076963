import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useFilterState } from './useFilterState';

// Page Size Options
export const defaultViewPerPageOptions = [
	{ label: 24, value: 24 },
	{ label: 48, value: 48 },
	{ label: 72, value: 72 },
	{ label: 120, value: 120 }
];

export const possibleColorsParents = [
	{ chex: '#000', name: 'black_color' },
	{ chex: '#fff', name: 'white_color' },
	{ chex: '#000000', name: 'black_color' },
	{ chex: '#ffffff', name: 'white_color' },
	{ chex: '#193057', name: 'navy_color' },
	{ chex: '#FFFF37', name: 'yellow_color' },
	{ chex: '#FFD466', name: 'gold_color' },
	{ chex: '#FFB82C', name: 'orange_color' },
	{ chex: '#C74F1F', name: 'brown_color' },
	{ chex: '#FF031B', name: 'red_color' },
	{ chex: '#00A7EA', name: 'blue_color' },
	{ chex: '#757575', name: 'dark-gray_color' },
	{ chex: '#FF5CC3', name: 'pink_color' },
	{ chex: '#00A64C', name: 'green_color' },
	{ chex: '#E4E2E2', name: 'gray_color' },
	{ chex: '#672B92', name: 'purple_color' },
	{ chex: '#CA8E22', name: 'beige_color' },
	{ chex: '#F0F0F0', name: 'silver_color' }
];

const useFilters = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const [filtersLoading, setFiltersLoading] = useState(false);
	const [filters, setFilters] = useState([]);

	const { filtersUrl, sortingOption, viewPerPageOption, page } = useFilterState(location);

	const [viewPerPageOptions, setViewPerPageOptions] = useState(defaultViewPerPageOptions);

	// Sorting Options
	const gridSortOptions = [
		{ label: t('sort_option1'), value: 'name asc' },
		{ label: t('sort_option2'), value: 'name desc' },
		{ label: t('sort_option3'), value: 'cdate desc' },
		{ label: t('sort_option4'), value: 'price asc' },
		{ label: t('sort_option5'), value: 'price desc' }
	];

	const [filtersModalVisible, setFiltersModalVisible] = useState(false);

	// Handle to add "View all" Option
	const handleSetViewAllProductsOption = (productsCount, minProductsCount, maxProductsCount, optionLabel) => {
		if (productsCount >= minProductsCount && productsCount <= maxProductsCount) {
			setViewPerPageOptions([...defaultViewPerPageOptions, { label: optionLabel, value: productsCount }]);
			return;
		}

		setViewPerPageOptions(defaultViewPerPageOptions);
	};

	return {
		filters,
		filtersUrl,
		page,
		filtersLoading,
		viewPerPageOptions,
		viewPerPageOption,
		gridSortOptions,
		sortingOption,
		filtersModalVisible,
		possibleColorsParents,
		setFilters,
		setFiltersLoading,
		handleSetViewAllProductsOption,
		setFiltersModalVisible
	};
};

export default useFilters;
