import { toast } from 'react-toastify';
import wishIcon from '~/images/wishlist-icon-active.svg';

export const notifyError = (message, closeAction = null) => {
	// Remove all toasts !
	toast.dismiss();

	toast.error(message, {
		position: toast.POSITION.TOP_CENTER,
		toastId: 'nfyRegisterApiError' + new Date().getTime(),
		onClose: closeAction,
		className: 'black-background'
	});
};

export const notifyInfoWishlist = (message, closeAction = null) => {
	// Remove all toasts !
	toast.dismiss();

	toast.info(message, {
		icon: <img src={wishIcon} alt="Wishlist Icon" />,
		position: toast.POSITION.TOP_CENTER,
		toastId: 'nfyRegisterMsg' + new Date().getTime(),
		onClose: closeAction,
		className: 'black-background',
		autoClose: 1500
	});
};

export const clearNotifications = () => {
	toast.dismiss();
};
