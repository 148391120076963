import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ProductCardColors = ({ product }) => {
	const { t } = useTranslation();

	const [showColors, setShowColors] = useState(false);

	// if (product.uniqueColors.length === 0) {
	// 	return null;
	// }

	if (product.vrnts && product.vrnts.length === 0) {
		return null;
	}

	return (
		<>
			<div className="color-info">
				{/* {product.uniqueColors.find(color => color.cval === product.chosenColor)?.chex && (
					<span
						className="color-img"
						style={{ background: product.uniqueColors.find(color => color.cval === product.chosenColor)?.chex }}></span>
				)} */}

				{/* {product.uniqueColors.length > 1 && ( */}
				<div
					className="color-count"
					// onClick={() => setShowColors(!showColors)}
				>
					{product?.vrnts?.length > 1 && product?.vrnts?.length + ' ' + t('product_colors')}
				</div>
				{/* )} */}
			</div>
			{/* {showColors && (
                <div className="display-colors">
                    {uniqueColors.map((color, index) =>
                        <span key={index} className="color" style={{ backgroundColor: color.chex }} onClick={() => product.handleColorChange(color.cval)}></span>
                    )}
                </div>
            )} */}
		</>
	);
};

ProductCardColors.propTypes = {
	product: PropTypes.object
};
export default ProductCardColors;
