import axios from 'axios';
import UserSession from '~/backend/user_session';

//i18n
import i18n from '~/i18n';

import { createBrowserHistory } from 'history';

const axiosApi = axios.create({
	baseURL: process.env.REACT_APP_BIZWEB_API_URL,
	crossdomain: true,
	headers: {
		'Accept': 'application/json',
		'Content-Language': i18n.language
	}
});

const parseApiError = error => {
	if (error?.response) {
		// Pass server response
		if (error.response.data) {
			if (error.response.data.msg) {
				return error.response.data.msg;
			}

			if (error.response.data.title) {
				return error.response.data.title;
			}

			return error.response.data;
		}

		var message = '';
		switch (error.response.status) {
			case 404:
				message = i18n.t('api_err_404');
				break;
			case 500:
				message = i18n.t('api_err_500');
				break;
			case 401:
				message = i18n.t('api_err_401');
				break;
			default:
				message = error.message;
				break;
		}

		return message;
	}

	if (error?.message) {
		return error.message;
	}

	return error;
};

axiosApi.interceptors.request.use(
	config => {
		// Apply localised API url
		config.baseURL = apiBaseUrl();

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

var refreshRetry = false;

axiosApi.interceptors.response.use(
	response => response,
	async error => {
		const originalConfig = error.config;

		try {
			if (
				error?.response?.status === 401 &&
				!refreshRetry &&
				!error.config.url.endsWith('/customers/login') &&
				!error.config.url.endsWith('/customers/refreshtoken')
			) {
				refreshRetry = true;

				// Refresh bearer token
				try {
					const rs = await axiosApi.post('/account/refreshtoken', {
						at: UserSession.getAccessToken(),
						rt: UserSession.getRefreshToken()
					});

					if (rs.data) {
						UserSession.initWithJson(rs.data);
					} else {
						refreshRetry = false;

						// *** Redirect to login page ***
						createBrowserHistory().push('/logout');
						window.location.reload();

						return Promise.reject(error);
					}
				} catch (e1) {
					refreshRetry = false;

					// *** Redirect to login page ***
					createBrowserHistory().push('/logout');
					window.location.reload();

					return Promise.reject(e1);
				}

				// Replace bearer of original request and re-run
				originalConfig.headers['Authorization'] = 'Bearer ' + UserSession.getAccessToken();

				return axiosApi(originalConfig);
			} else {
				refreshRetry = false;
				if (originalConfig?.doNotParseError) return Promise.reject(error);
				return Promise.reject(parseApiError(error));
			}
		} catch (e) {
			refreshRetry = false;
			return Promise.reject(e);
		}
	}
);

/** useAuth is ignored. By default if there is an authToken we send it */
function composeAxiosConfig(useAuth, signal, doNotParseError) {
	var config = { doNotParseError, headers: {} };

	// pass language to proper header
	const lang = UserSession.getActiveLanguage();
	if (lang) config.headers['Accept-Language'] = lang;

	// pass site code to proper header
	const siteCode = UserSession.getActiveSiteCode?.();
	if (siteCode) config.headers['BizWeb-SiteCode'] = siteCode;

	var token = UserSession.getAccessToken();
	if (token) config.headers.Authorization = `Bearer ${token}`;

	if (signal) config.signal = signal;

	return config;
}

export async function get(url, cached = false, useAuth = true, signal = null) {
	if (!cached) {
		url = url + (url.includes('?') ? '&' : '?') + 'v=' + Date.now();
	}

	return axiosApi
		.get(url, composeAxiosConfig(useAuth, signal))
		.then(response => response.data)
		.catch(err => {
			throw err;
		});
}

export async function getRaw(url, cached = false, useAuth = true, signal = null) {
	if (!cached) {
		url = url + (url.includes('?') ? '&' : '?') + 'v=' + Date.now();
	}

	console.debug('Axios GET:' + url);

	return axiosApi
		.get(url, composeAxiosConfig(useAuth, signal))
		.then(response => response)
		.catch(err => {
			throw err;
		});
}

export async function post(url, data, useAuth = true, signal = null) {
	console.debug('Axios POST:' + url);

	return axiosApi
		.post(url, { ...data }, composeAxiosConfig(useAuth, signal))
		.then(response => response.data)
		.catch(err => {
			throw err;
		});
}

export async function postRaw(url, data, useAuth = true, signal = null) {
	console.debug('Axios POST:' + url);

	return axiosApi.post(url, { ...data }, composeAxiosConfig(useAuth, signal, true));
}

export async function put(url, data, useAuth = true) {
	console.debug('Axios PUT:' + url);

	return axiosApi.put(url, { ...data }, composeAxiosConfig(useAuth)).then(response => response.data);
}

export async function del(url, useAuth = true) {
	console.debug('Axios DELETE:' + url);

	return axiosApi.delete(url, composeAxiosConfig(useAuth)).then(response => response.data);
}

export const apiBaseUrl = () => {
	const lang = UserSession.getActiveLanguage();
	// return process.env.REACT_APP_BIZWEB_API_URL + '/' + lang;
	return process.env.REACT_APP_BIZWEB_API_URL + '/el';
};

export function onDataGridRowUpdatingMergeUnchanged(e) {
	e.newData = { ...e.oldData, ...e.newData };
}

export function fileUploaderHeaders() {
	return {
		Authorization: 'Bearer ' + UserSession.getAccessToken()
	};
}
