import { Outlet, useLocation } from 'react-router';
import HeaderCheckout from '../checkout/HeaderCheckout';
import HeaderNew from './NewHeader/Header';
import Footer from './footer/Footer';
// import Header from './header/Header';

const LayoutClassic = ({ children }) => {
	const { pathname } = useLocation();

	const defineLayoutToRender = () => {
		if (pathname.includes('/checkout-step-1')) {
			return (
				<>
					<HeaderCheckout step={1} />
					<Outlet />
				</>
			);
		} else if (pathname.includes('/checkout-step-2')) {
			return (
				<>
					<HeaderCheckout step={2} />
					<Outlet />
				</>
			);
		} else if (pathname.includes('/checkout-step-3')) {
			return (
				<>
					<HeaderCheckout step={3} />
					<Outlet />
				</>
			);
		} else if (pathname.startsWith('/checkout-success') || pathname.includes('/checkout-failure')) {
			return (
				<>
					<HeaderCheckout step={3} />
					<Outlet />
				</>
			);
		} else {
			return (
				<>
					<HeaderNew />
					{/* <Header /> */}
					{children}
					<Footer />
				</>
			);
		}
	};

	return defineLayoutToRender();
};

export default LayoutClassic;
