import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { api_getByParentCategoryId, api_getCategoryByCode } from '~/backend/api_calls';
import { chunkArray } from '~/common/helper';
import arrowrighticon from '~/images/arrow-right.svg';

function groupObjectsByFirstWord(objects) {
	// Helper function to get the first word of a string
	const getFirstWord = str => str.split(' ')[0];

	// Group objects by the first word of tl property
	const groups = objects.reduce((acc, obj) => {
		const firstWord = getFirstWord(obj.tl);
		if (!acc[firstWord]) {
			acc[firstWord] = [];
		}
		acc[firstWord].push(obj);
		return acc;
	}, {});

	// Prepare the result array
	const result = [];

	// Split each group into chunks of 2 and create the final result objects
	Object.keys(groups).forEach(key => {
		const group = groups[key];
		for (let i = 0; i < group.length; i += 2) {
			result.push({
				title: key,
				items: group.slice(i, i + 2)
			});
		}
	});

	return result;
}

const CollectionLayout = ({ parentMenuItem, menuItems, setActiveMenuItem, activeSubMenuItem, setActiveSubMenuItem }) => {
	const [activeBrand, setActiveBrand] = useState(null);
	const [manufacturers, setManufacturers] = useState([]);
	const collectionMenuItems = chunkArray(
		menuItems.filter(x => x.pid === parentMenuItem.id),
		2
	);
	const groupedCollections = useMemo(
		() => groupObjectsByFirstWord(menuItems.filter(x => x.pid === parentMenuItem.id)),
		[menuItems, parentMenuItem.id]
	);

	useEffect(() => {
		api_getCategoryByCode('BRANDS')
			.then(json => {
				api_getByParentCategoryId(json.id)
					.then(categories => {
						setManufacturers(categories.items);
					})
					.catch(error => {
						console.log(error);
					});
			})
			.catch(error => {
				console.log(error);
			});
	}, []);

	console.log(activeSubMenuItem.tl ?? 'something');

	return (
		<div className="collection-layout">
			<div className="header">
				<button
					className="mobile-back"
					onClick={() => (activeSubMenuItem.tl ? setActiveSubMenuItem({}) : activeBrand ? setActiveBrand(null) : setActiveMenuItem({}))}>
					<img src={arrowrighticon} alt="Go back" />
				</button>
				<span>{activeSubMenuItem.tl ?? activeBrand ?? 'COLLECTIONS'}</span>
			</div>
			<div className="brands-container">
				{manufacturers.map((brand, index) => (
					<Link key={index} to={brand.eurl}>
						<img src={brand.img1} alt={brand.nm} />
					</Link>
				))}
			</div>
			<div className="collection-menu-items">
				<div className="grouped-collection">
					{groupedCollections.map((groupedCollection, index) => (
						<div key={index}>
							<li onClick={() => setActiveBrand(groupedCollection.title)}>
								{groupedCollection.title}
								<img src={arrowrighticon} alt="View more" />
							</li>
							<div className={`children ${groupedCollection.title === activeBrand ? 'active' : ''}`}>
								{groupedCollection.items.map((x, index) => (
									<div key={index}>
										<li onClick={() => setActiveSubMenuItem(x)}>
											{x.tl}
											<img src={arrowrighticon} alt="View more" />
										</li>
										<div className={`sub-children ${activeSubMenuItem.id === x.id ? 'active' : ''}`}>
											{menuItems
												.filter(menuItem => menuItem.pid === x.id)
												.map((x, index) => (
													<Link to={x.nu} key={index}>
														{x.tl}
													</Link>
												))}
										</div>
									</div>
								))}
							</div>
						</div>
					))}
				</div>
				{collectionMenuItems.map((chunk, index) => (
					<div key={index} className="group">
						{chunk.map((x, index) => (
							<div key={index} className="column">
								<b>{x.tl}</b>
								<ul className="children">
									{menuItems
										.filter(child => child.pid === x.id)
										.map((x, index) => (
											<Link key={index} to={x.nu}>
												{x.tl}
											</Link>
										))}
								</ul>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

export default CollectionLayout;
