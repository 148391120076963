export const consentCookiesAction = args => {
	return {
		type: 'consent-cookies',
		args
	};
};

export const openCookiesAction = () => {
	return {
		type: 'open-cookies'
	};
};

export const closeCookiesAction = () => {
	return {
		type: 'close-cookies'
	};
};
