import { Link } from 'react-router-dom';
import arrowrighticon from '~/images/arrow-right.svg';
import closeicon from '~/images/close-button.svg';
import SubMenu from './SubMenu';

const MenuWrapper = ({ menuItems, activeMenuItem, setActiveMenuItem, activeSubMenuItem, setActiveSubMenuItem, menuOpen, setMenuOpen }) => {
	const showAllLink = code => {
		if (code === 'BRANDS')
			return (
				<Link className="show-all-link" to={'/categories/brand'}>
					ΔΕΣ ΤΑ ΟΛΑ
				</Link>
			);
		if (code === 'OFFERS')
			return (
				<Link className="show-all-link" to={'/categories/prosfores'}>
					ΔΕΣ ΤΑ ΟΛΑ
				</Link>
			);
		if (code === 'SPORTS')
			return (
				<Link className="show-all-link" to={'/categories/athlhmata'}>
					ΔΕΣ ΤΑ ΟΛΑ
				</Link>
			);
		return null;
	};

	return (
		<div className={`menu-wrapper ${menuOpen ? 'menu-mobile-open' : ''}`}>
			<button className="close-menu-mobile" onClick={() => setMenuOpen(false)}>
				<img src={closeicon} alt="close menu" />
			</button>
			<ul className="menu-items">
				{menuItems
					.filter(x => !x.pid)
					.map((item, index) => (
						<div className={`menu-item-container ${item.cd} ${activeMenuItem.id === item.id ? 'active' : ''}`} key={index}>
							{item.img2 && (
								<div className="image-mobile" onClick={() => setActiveMenuItem(activeMenuItem.id === item.id ? {} : item)}>
									<img src={item.img2} alt={item.tl} />
								</div>
							)}
							<li className={`menu-item`} onClick={() => setActiveMenuItem(activeMenuItem.id === item.id ? {} : item)}>
								{item.tl}
								{showAllLink(item.cd)}
							</li>
							<button className="open-children-mobile" onClick={() => setActiveMenuItem(activeMenuItem.id === item.id ? {} : item)}>
								<img src={arrowrighticon} alt="Browse category icon" />
							</button>
							<SubMenu
								parentMenuItem={item}
								menuItems={menuItems}
								setActiveMenuItem={setActiveMenuItem}
								activeSubMenuItem={activeSubMenuItem}
								setActiveSubMenuItem={setActiveSubMenuItem}
							/>
						</div>
					))}
			</ul>
		</div>
	);
};

export default MenuWrapper;
